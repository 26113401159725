<template>
  <TTModuleMenu
    :items-tree="menu"
    @sign-out="onSignOutHandler"
  />
</template>

<script>
/* eslint-disable vue/require-name-property */

export default {
  inject: ['Names'],
  data() {
    return {
      menu: [
        [
          {
            title: 'Цвета',
            to: { name: this.Names.R_UIKIT_COLORS },
            icon: 'fal fa-palette',
            position: 'prepend',
          },
          {
            title: 'Typography',
            to: { name: this.Names.R_UIKIT_TYPOGRAPHY },
            icon: 'fal fa-text',
          },
          {
            title: 'Кнопки',
            to: { name: this.Names.R_UIKIT_BTN },
            icon: 'fas fa-rectangle-landscape',
          },
          {
            title: 'Чекбоксы и радиобаттоны',
            to: { name: this.Names.R_UIKIT_CHECKBOX },
            icon: 'fal fa-radio-alt',
            badge: {
              color: 'tt-light-blue', value: true,
            },
          },
          {
            title: 'Текстовые поля',
            to: { name: this.Names.R_UIKIT_TEXTFIELD },
            icon: 'fal fa-align-justify',
          },
          {
            title: 'Tables',
            to: { name: this.Names.R_UIKIT_DATA_TABLE },
            icon: 'fal fa-th',
            badge: {
              color: 'tt-light-blue', value: true,
            },
          },
          {
            title: 'Select',
            to: { name: this.Names.R_UIKIT_SELECT },
            icon: 'fal fa-hand-pointer',
          },
          {
            title: 'Autocomplete',
            to: { name: this.Names.R_UIKIT_AUTOCOMPLETE },
            icon: 'fal fa-list-ul',
            badge: {
              color: 'tt-light-blue', value: true,
            },
          },
          {
            title: 'Textarea',
            to: { name: this.Names.R_UIKIT_TEXTAREA },
            icon: 'fal fa-paragraph',
            badge: {
              color: 'tt-light-blue', value: true,
            },
          },
          {
            title: 'Алерты',
            to: { name: this.Names.R_UIKIT_ALERT },
            icon: 'fal fa-info',
          },
          {
            title: 'Тулбары',
            to: { name: this.Names.R_UIKIT_TOOLBAR },
            icon: 'fal fa-tools',
            badge: {
              color: 'tt-light-blue', value: true,
            },
          },
        ],
        [
          {
            title: 'Grid',
            to: { name: this.Names.R_UIKIT_GRID },
            icon: 'fal fa-th',
            badge: {
              color: 'tt-light-blue', value: true,
            },
          },
          {
            title: 'Holy Grail Layout',
            to: { name: this.Names.R_UIKIT_HOLY_GRAIL_LAYOUT },
            icon: 'fal fa-angel',
            children: [
              {
                title: 'Потомок 1',
                icon: 'fal fa-angel',
                badge: {
                  color: 'tt-light-blue', value: true,
                },
                to: { name: this.Names.R_UIKIT_HOLY_GRAIL_LAYOUT, params: { id: 1 } },
              },
              {
                title: 'Потомок 2 с длинным названием',
                icon: 'fal fa-angel',
                to: { name: this.Names.R_UIKIT_HOLY_GRAIL_LAYOUT, params: { id: 2 } },
              },
            ],
          },
        ],
        [
          {
            title: 'Пример с баджем',
            icon: 'fal fa-certificate',
            badge: true,
          },
          {
            title: 'Пример без баджа',
            icon: 'fal fa-certificate',
          },
          {
            title: 'Пример c потомками',
            icon: 'fal fa-certificate',
            badge: {
              color: 'tt-light-blue', value: false,
            },
            children: [
              {
                title: 'Потомок 1',
                icon: 'fal fa-certificate',
                badge: {
                  color: 'tt-light-blue', value: true,
                },
              },
              {
                title: 'Потомок 2',
                icon: 'fal fa-certificate',
              },
            ],
          },
          {
            title: 'Внешняя ссылка без таргета',
            icon: 'fal fa-certificate',
            href: 'https://www.google.com/',
          },
          {
            title: 'Внешняя ссылка с таргетом',
            icon: 'fal fa-certificate',
            href: 'https://www.google.com/',
            target: '_blank',
          },
          {
            title: 'Внешнии сслыки',
            icon: 'fal fa-certificate',
            children: [
              {
                title: 'Потомок с таргетом',
                icon: 'fal fa-certificate',
                href: 'https://www.google.com/',
                target: '_blank',
              },
              {
                title: 'Потомок без таргета',
                icon: 'fal fa-certificate',
                href: 'https://www.google.com/',
              },
            ],
          },
        ],
      ],
    };
  },

  methods: {
    onSignOutHandler() {
      console.log('SIGNED OUT');
    },
  },
};
</script>
